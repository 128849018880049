.rnf-notification-feed__knock-branding {
  /* display: none; */
  visibility: hidden;
}

.rnf-notification-icon-button {
  color: #787878 !important;
  padding-top: 12px !important;
  padding-right: 10px !important;

}

.rnf-notification-icon-button:hover, .rnf-notification-icon-button:focus {
  cursor: pointer !important;
  color: #5cb5b3 !important;
  background-color: transparent !important;
  background: none !important;

}

/* .rnf-feed-provider {
  background-color: red !important;
  display: flex;
justify-content: flex-end;
} */

/* 
select option:hover,
select option:checked {
    background-color: yellow !important;
} */

.rnf-dropdown,
.rnf-dropdown--light,
.rnf-dropdown button:hover {
  border-color: white !important;

}

.rnf-dropdown:visited,
.rnf-dropdown:focus,
.rnf-dropdown::after,
.rnf-dropdown::before,
.rnf-dropdown:visited,
.rnf-dropdown:target,
.rnf-dropdown,
.rnf-dropown:active,
.rnf-dropdown:hover {
  /* background-color: red;       */
  border-color: white !important;
}

.rnf-dropdown--light:visited,
.rnf-dropdown--light:focus,
.rnf-dropdown--light::after,
.rnf-dropdown--light::before,
.rnf-dropdown--light:visited,
.rnf-dropdown--lightn:target,
.rnf-dropdown--light,
.rnf-dropdown--light:active,
.rnf-dropdown--light:hover {
  /* background-color: red;       */
  border-color: white !important;
}

.rnf-dropdown button::after {
  border-color: white !important;

}


/* .rnf-dropdown select:focus > option:checked {
    background: #5cb5b3 !important;
    border-color: white !important;
    color: white;

} */

.rnf-dropdown select:focus {
  /* background: #5cb5b3 !important; */
  color: #787878 !important;
  border-color: white !important;
}

.rnf-dropdown option:hover {
  background-color: #5cb5b3 !important;
  color: #fff;
}

.rnf-notification-cell__content {
  color: #787878;
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 500;
}

.rnf-notification-cell__timestamp {
  color: #787878;
  font-family: 'Open Sans';
  font-size: 12px;
}

.rnf-notification-cell:hover {
  background-color: rgba(92, 181, 179, 0.1) !important;
}

.rnf-unseen-badge {
  background: #5cb5b3 !important;
  margin-top: 2px;
  border-radius: 10px !important;
  height: 20px;
  min-width: 20px;
}

.rnf-unseen-badge__count {
  font-size: 12px;
  line-height: 12px;
  padding-left: 6px;
  padding-right: 6px;
}

.rnf-notification-cell__unread-dot {
  background: #5cb5b3 !important;
  /* width: 8px;
    height: 8px; */
  margin-top: 10px;
}

/* .rnf-notification-cell__content p[foo$="new"] {

 color: red;
  } */


/* .rnf-notification-cell__content p::nth-letter(){

    color: red;
     } */