.btn,
.btn-primary {
    color: #5cb5b3 !important;
    background-color: #fff !important;
    border-color: #5cb5b3 !important;
    outline: none !important;
    box-shadow: none !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
    color: #fff !important;
    background-color: #5cb5b3 !important;
    border-color: #5cb5b3 !important;
    outline: none;
    box-shadow: none;
}
.fc-toolbar-title {
    color: #787878 !important;
    margin-bottom: 10 !important;
}
.fc-col-header-cell {
    color: #787878 !important;
}
.fc-daygrid-day-frame {
    color: #787878 !important;
}
.fc-day-today {
    background-color: #e8f4f8 !important;
    /* opacity: 0.1; */
}
/* .fc-event-main{
    border-color: #000 !important;
} */

@media (max-width: 767.98px) {
    .fc-toolbar.fc-header-toolbar {
        display: block;
        text-align: left;
    }
    .fc-header-toolbar .fc-toolbar-chunk {
        display: block;
    }
    .fc-toolbar.fc-footer-toolbar {
        display: block;
        text-align: left;
    }
    .fc-footer-toolbar .fc-toolbar-chunk {
        display: block;
    }

    #fc-dom-86 {
        font-family: 'Open Sans';
    }
    .btn-group {
        font-family: 'Open Sans';
        min-width: 360px;
        max-width: 400px;
    }
}
